import {
    DOWNLOAD_ERRORS_CSV,
    DOWNLOAD_ERRORS_CSV_SUCCESS,
    GET_ALL_CONTACTS_FAILURE,
    GET_ALL_CONTACTS_INIT,
    GET_ALL_CONTACTS_SUCCESS,
    PURCHASE_REG_FILE_UPLOAD,
    PURCHASE_REG_FILE_UPLOAD_SUCCESS,
    VALIDATE_CSV_FILE,
    VALIDATE_CSV_FILE_SUCCESS,
    UPDATE_CONTACT_BY_ID,
    UPDATE_CONTACT_BY_ID_SUCCESS,
    UPDATE_CONTACT_BY_ID_FAILURE
} from "src/actions/actionTypes";

const initialState = {
    allContactsList: [],
    contactLoading: false,
    csvErrorsResponce: null,
    csvValidationResp: null,
    purchaseCsvUploadResp: null,
    updateContactResponce: null

};

export default function contactsReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ALL_CONTACTS_INIT: {
            return { ...state, allContactsList: [], contactLoading: true }
        }
        case GET_ALL_CONTACTS_SUCCESS: {
            // const temp = [{ "groupId": 16, "mailId": "sarath@lotuswireless.com", "ledgerName": "Mangalore Electricals", "mailingName": "Mangalore Electricals", "ledgerId": 13, "phoneNumber": "8099986682", "contactList": [{ "actionMode": null, "id": 31, "name": "avinash", "number": "6302079311", "mailId": "sarath@lotuswireless.com", "type": "Contact2", "createdBy": "Avinash", "modifiedBy": null, "ledgerId": 13, "mailingName": null }, { "actionMode": null, "id": 21, "name": "avinash", "number": "23434435", "mailId": "avi@lotuswireless.com", "type": "Contact2", "createdBy": "Avinash", "modifiedBy": null, "ledgerId": 13, "mailingName": null }] }, { "groupId": 16, "mailId": "avinash@lotusinsights.in", "ledgerName": "Alekya Enterprises", "mailingName": "Alekya Enterprises Pvt.", "ledgerId": 1, "phoneNumber": "8523854216666", "contactList": [{ "actionMode": null, "id": 31, "name": "avinash", "number": "6302079311", "mailId": "avinash@lotusinsigh.com", "type": "Contact2", "createdBy": "Avinash", "modifiedBy": null, "ledgerId": 1, "mailingName": null }] }, { "groupId": 16, "mailId": "asfdasfasfsf", "ledgerName": "Suhaj Electricals", "mailingName": "Suhaj Electricals", "ledgerId": 22, "phoneNumber": "", "contactList": [{ "actionMode": null, "id": 31, "name": "avinash", "number": "6302079311", "mailId": "Suhaj@lotusinsigh.com", "type": "Contact2", "createdBy": "Avinash", "modifiedBy": null, "ledgerId": 22, "mailingName": null }] }, { "groupId": 16, "mailId": "avinash@lotusinsights.in", "ledgerName": "Kalkeya Electronics", "mailingName": "Kalkeya Electronics", "ledgerId": 11, "phoneNumber": "8523854216", "contactList": [{ "actionMode": null, "id": 31, "name": "avinash", "number": "6302079311", "mailId": "Kalkeya@lotusinsigh.com", "type": "Contact2", "createdBy": "Avinash", "modifiedBy": null, "ledgerId": 11, "mailingName": null }] }];
            // return { ...state, allContactsList: temp, contactLoading: false }

            return { ...state, allContactsList: action.payload, contactLoading: false }
        }
        case GET_ALL_CONTACTS_FAILURE: {
            return { ...state, allContactsList: [], contactLoading: false }
        }


        case UPDATE_CONTACT_BY_ID: {
            return { ...state, updateContactResponce: null };
        }
        case UPDATE_CONTACT_BY_ID_SUCCESS: {
            return { ...state, updateContactResponce: action.payload };
        }

        case VALIDATE_CSV_FILE: {
            return { ...state, csvValidationResp: null };
        }
        case VALIDATE_CSV_FILE_SUCCESS: {
            return { ...state, csvValidationResp: action.payload };
        }

        case DOWNLOAD_ERRORS_CSV: {
            return { ...state, csvErrorsResponce: null };
        }
        case DOWNLOAD_ERRORS_CSV_SUCCESS: {
            return { ...state, csvErrorsResponce: action.payload };
        }

        case PURCHASE_REG_FILE_UPLOAD: {
            return { ...state, purchaseCsvUploadResp: null };
        }
        case PURCHASE_REG_FILE_UPLOAD_SUCCESS: {
            return { ...state, purchaseCsvUploadResp: action.payload };
        }

        default:
            return state;
    }
}
