import { productionServiceUrl } from "./serviceUrls";
import moment from "moment";

const { default: cookie } = require("react-cookies")

export const getCompany = () => {
  const company = cookie.load("SELECTED_COMPANY")
  return company;
};
export const getTenant = () => {
  const tenent = cookie.load("TENANT")
  return tenent;
};

export const getUserData = () => {
  return cookie.load("USER_DATA") || "";
};


export const getUserName = () => {
  if (cookie.load("USER_DATA")) {
    const userData = cookie.load("USER_DATA")
    return userData.userName || "";
  }
};

export const getUserId = () => {
  if (cookie.load("USER_DATA")) {
    const userData = cookie.load("USER_DATA")
    return userData.userId || "";
  }
};

export const getUserEmail = () => {
  if (cookie.load("USER_DATA")) {
    const userData = cookie.load("USER_DATA")
    return userData.userEmail || "";
  }
};

export const getUserAuthType = () => {
  if (cookie.load("USER_DATA")) {
    const userData = cookie.load("USER_DATA")
    return userData.auth_type || "";
  }
};

export const reportUrl = productionServiceUrl + "python/"
export const getCurrentFinancialYear = () => {
  var fiscalyear = "";
  var today = new Date();
  if ((today.getMonth() + 1) <= 3) {
    fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
  } else {
    fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
  }
  let arr = fiscalyear.split("-")
  return arr
}

export const bookFromAndToDate = () => {
  const bookFromAndToDate = cookie.load("BOOK_START_AND_END_DATE")
  return bookFromAndToDate
}

export const nameShortHand = (name) => {
  return name.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
}

export const getNotificationData = () => {
  if (cookie.load("NOTIFICATION_SUBSCRIPTION_DATA")) {
    const notificationData = cookie.load("NOTIFICATION_SUBSCRIPTION_DATA")
    return notificationData || "";
  }
};

export const phoneRegex = () => {
  return RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
}

export const getContriesList = () => {
  const list = [
    {
      "value": "Bangladesh",
      "lable": "Bangladesh"
    },
    {
      "value": "Bhutan",
      "lable": "Bhutan"
    },
    {
      "value": "Botswana",
      "lable": "Botswana"
    },
    {
      "value": "Egypt",
      "lable": "Egypt"
    },
    {
      "value": "Ghana",
      "lable": "Ghana"
    },
    {
      "value": "Hong Kong",
      "lable": "Hong Kong"
    },
    {
      "value": "India",
      "lable": "India"
    },
    {
      "value": "Indonesia",
      "lable": "Indonesia"
    },
    {
      "value": "Kenya",
      "lable": "Kenya"
    },
    {
      "value": "Kingdom Of Bahrain",
      "lable": "Kingdom Of Bahrain"
    },
    {
      "value": "Kuwait",
      "lable": "Kuwait"
    },
    {
      "value": "Liberia",
      "lable": "Liberia"
    },
    {
      "value": "Malawi",
      "lable": "Malawi"
    },
    {
      "value": "Malaysia",
      "lable": "Malaysia"
    },
    {
      "value": "Myanmar(burma)",
      "lable": "Myanmar(burma)"
    },
    {
      "value": "Nepal",
      "lable": "Nepal"
    },
    {
      "value": "Nigeria",
      "lable": "Nigeria"
    },
    {
      "value": "Philippines",
      "lable": "Philippines"
    },
    {
      "value": "Qatar",
      "lable": "Qatar"
    },
    {
      "value": "Saudi Arabia",
      "lable": "Saudi Arabia"
    },
    {
      "value": "Singapore",
      "lable": "Singapore"
    },
    {
      "value": "South Africa",
      "lable": "South Africa"
    },
    {
      "value": "Sri Lanka",
      "lable": "Sri Lanka"
    },
    {
      "value": "Sultanate Of Oman",
      "lable": "Sultanate Of Oman"
    },
    {
      "value": "Tanzania",
      "lable": "Tanzania"
    },
    {
      "value": "Thailand",
      "lable": "Thailand"
    },
    {
      "value": "UAE",
      "lable": "UAE"
    },
    {
      "value": "Uganda",
      "lable": "Uganda"
    },
    {
      "value": "UK",
      "lable": "UK"
    },
    {
      "value": "United States Of America",
      "lable": "United States Of America"
    },
    {
      "value": "Zambia",
      "lable": "Zambia"
    },
  ]
  return list
}

export const getListOfStates = () => {
  let list = [
    {
      "code": "AN",
      "name": "Andaman and Nicobar Islands"
    },
    {
      "code": "AP",
      "name": "Andhra Pradesh"
    },
    {
      "code": "AR",
      "name": "Arunachal Pradesh"
    },
    {
      "code": "AS",
      "name": "Assam"
    },
    {
      "code": "BR",
      "name": "Bihar"
    },
    {
      "code": "CG",
      "name": "Chandigarh"
    },
    {
      "code": "CH",
      "name": "Chhattisgarh"
    },
    {
      "code": "DH",
      "name": "Dadra and Nagar Haveli"
    },
    {
      "code": "DD",
      "name": "Daman and Diu"
    },
    {
      "code": "DL",
      "name": "Delhi"
    },
    {
      "code": "GA",
      "name": "Goa"
    },
    {
      "code": "GJ",
      "name": "Gujarat"
    },
    {
      "code": "HR",
      "name": "Haryana"
    },
    {
      "code": "HP",
      "name": "Himachal Pradesh"
    },
    {
      "code": "JK",
      "name": "Jammu and Kashmir"
    },
    {
      "code": "JH",
      "name": "Jharkhand"
    },
    {
      "code": "KA",
      "name": "Karnataka"
    },
    {
      "code": "KL",
      "name": "Kerala"
    },
    {
      "code": "LD",
      "name": "Lakshadweep"
    },
    {
      "code": "MP",
      "name": "Madhya Pradesh"
    },
    {
      "code": "MH",
      "name": "Maharashtra"
    },
    {
      "code": "MN",
      "name": "Manipur"
    },
    {
      "code": "ML",
      "name": "Meghalaya"
    },
    {
      "code": "MZ",
      "name": "Mizoram"
    },
    {
      "code": "NL",
      "name": "Nagaland"
    },
    {
      "code": "OR",
      "name": "Odisha"
    },
    {
      "code": "PY",
      "name": "Puducherry"
    },
    {
      "code": "PB",
      "name": "Punjab"
    },
    {
      "code": "RJ",
      "name": "Rajasthan"
    },
    {
      "code": "SK",
      "name": "Sikkim"
    },
    {
      "code": "TN",
      "name": "Tamil Nadu"
    },
    {
      "code": "TS",
      "name": "Telangana"
    },
    {
      "code": "TR",
      "name": "Tripura"
    },
    {
      "code": "UP",
      "name": "Uttar Pradesh"
    },
    {
      "code": "UK",
      "name": "Uttarakhand"
    },
    {
      "code": "WB",
      "name": "West Bengal"
    }
  ]
  return list
}

export const getDateOptions = () => {
  let list = [
    {
      "code": "Last 365 Days",
      "name": "Last 365 Days"
    },
    {
      "code": "Today",
      "name": "Today"
    },
    {
      "code": "Yesterday",
      "name": "Yesterday"
    },
    {
      "code": "This Week",
      "name": "This Week"
    },
    {
      "code": "This Month",
      "name": "This Month"
    },
    {
      "code": "This Quarter",
      "name": "This Quarter"
    },
    {
      "code": "This Fiscal Quarter",
      "name": "This Fiscal Quarter"
    },
    {
      "code": "This Year",
      "name": "This Year"
    },
    {
      "code": "This Financial Year",
      "name": "This Financial Year"
    },
    {
      "code": "Last Week",
      "name": "Last Week"
    },
    {
      "code": "Last Month",
      "name": "Last Month"
    },
    {
      "code": "Last Quarter",
      "name": "Last Quarter"
    },
    {
      "code": "Last Fiscal Quarter",
      "name": "Last Fiscal Quarter"
    },
    {
      "code": "Last Year",
      "name": "Last Year"
    },
    {
      "code": "Last Financial Year",
      "name": "Last Financial Year"
    }
  ]
  return list
}


export const monthsList = [
  {
    "code": "January",
    "name": "January"
  },
  {
    "code": "February",
    "name": "February"
  },
  {
    "code": "March",
    "name": "March"
  },
  {
    "code": "April",
    "name": "April"
  },
  {
    "code": "May",
    "name": "May"
  },
  {
    "code": "June",
    "name": "June"
  },
  {
    "code": "July",
    "name": "July"
  },
  {
    "code": "August",
    "name": "August"
  },
  {
    "code": "September",
    "name": "September"
  },
  {
    "code": "October",
    "name": "October"
  },
  {
    "code": "November",
    "name": "November"
  },
  {
    "code": "December",
    "name": "December"
  }
]

export const finMonthsList = [
  {
    "code": "4",
    "name": "30th April"
  },
  {
    "code": "5",
    "name": "31st May"
  },
  {
    "code": "6",
    "name": "30th June"
  },
  {
    "code": "7",
    "name": "31st July"
  },
  {
    "code": "8",
    "name": "31st August"
  },
  {
    "code": "9",
    "name": "30th September"
  },
  {
    "code": "10",
    "name": "31st October"
  },
  {
    "code": "11",
    "name": "30th November"
  },
  {
    "code": "12",
    "name": "31st December"
  },
  {
    "code": "1",
    "name": "31st January"
  },
  {
    "code": "2",
    "name": "28th February"
  },
  {
    "code": "3",
    "name": "31st March"
  },
]
export const finMonthsListPAndL = [
  {
    "code": "4",
    "name": "30th April"
  },
  {
    "code": "5",
    "name": "31st May"
  },
  {
    "code": "6",
    "name": "30th June"
  },
  {
    "code": "7",
    "name": "31st July"
  },
  {
    "code": "8",
    "name": "31st August"
  },
  {
    "code": "9",
    "name": "30th September"
  },
  {
    "code": "10",
    "name": "31st October"
  },
  {
    "code": "11",
    "name": "30th November"
  },
  {
    "code": "12",
    "name": "31st December"
  },
  {
    "code": "1",
    "name": "31st January"
  },
  {
    "code": "2",
    "name": "28th February"
  },
  {
    "code": "3",
    "name": "31st March"
  },
  {
    "code": "1st Quarter",
    "name": "1st Quarter"
  },
  {
    "code": "2nd Quarter",
    "name": "2nd Quarter"
  },
  {
    "code": "3rd Quarter",
    "name": "3rd Quarter"
  },
  {
    "code": "4th Quarter",
    "name": "4th Quarter"
  },
  {
    "code": "1st Half",
    "name": "1st Half"
  },
  {
    "code": "2nd Half",
    "name": "2nd Half"
  },
  {
    "code": "Yearly",
    "name": "Yearly"
  }
]

export const finMonthsListLeaphPAndL = [
  {
    "code": "4",
    "name": "30th April"
  },
  {
    "code": "5",
    "name": "31st May"
  },
  {
    "code": "6",
    "name": "30th June"
  },
  {
    "code": "7",
    "name": "31st July"
  },
  {
    "code": "8",
    "name": "31st August"
  },
  {
    "code": "9",
    "name": "30th September"
  },
  {
    "code": "10",
    "name": "31st October"
  },
  {
    "code": "11",
    "name": "30th November"
  },
  {
    "code": "12",
    "name": "31st December"
  },
  {
    "code": "1",
    "name": "31st January"
  },
  {
    "code": "2",
    "name": "29th February"
  },
  {
    "code": "3",
    "name": "31st March"
  },
  {
    "code": "1st Quarter",
    "name": "1st Quarter"
  },
  {
    "code": "2nd Quarter",
    "name": "2nd Quarter"
  },
  {
    "code": "3rd Quarter",
    "name": "3rd Quarter"
  },
  {
    "code": "4th Quarter",
    "name": "4th Quarter"
  },
  {
    "code": "1st Half",
    "name": "1st Half"
  },
  {
    "code": "2nd Half",
    "name": "2nd Half"
  },
  {
    "code": "Yearly",
    "name": "Yearly"
  }
]

export const finMonthsListLeaph = [
  {
    "code": "4",
    "name": "30th April"
  },
  {
    "code": "5",
    "name": "31st May"
  },
  {
    "code": "6",
    "name": "30th June"
  },
  {
    "code": "7",
    "name": "31st July"
  },
  {
    "code": "8",
    "name": "31st August"
  },
  {
    "code": "9",
    "name": "30th September"
  },
  {
    "code": "10",
    "name": "31st October"
  },
  {
    "code": "11",
    "name": "30th November"
  },
  {
    "code": "12",
    "name": "31st December"
  },
  {
    "code": "1",
    "name": "31st January"
  },
  {
    "code": "2",
    "name": "29th February"
  },
  {
    "code": "3",
    "name": "31st March"
  },
]

export const getMonthDateRange = (year, month) => {
  var moment = require('moment');

  // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
  // array is 'year', 'month', 'day', etc
  var startDate = moment([year, month - 1]);

  // Clone the value before .endOf()
  var endDate = moment(startDate).endOf('month');

  // just for demonstration:
  console.log(startDate.toDate());
  console.log(endDate.toDate());

  // make sure to call toDate() for plain JavaScript date type
  return { start: startDate.toDate(), end: endDate.toDate() };
}

export const getCurrentMont = () => {
  let currMonthName = moment().format('MMMM');
  let days = moment().daysInMonth()
  if (days === 31) {
    return days + "st " + currMonthName
  } else {
    return days + "th " + currMonthName
  }

}


export const getCurrentMontNum = () => {
  let currMonthNum = moment().format('M');
  return currMonthNum
}

export const getDaysInMonth = (year, month) => {
  let date = year + "-" + month
  let days = moment(date, "YYYY-MM").daysInMonth() // 29
  return days
}


export const placeOfSupply = () => {
  return [
    {
      "name": "Jammu And Kashmir",
      "code": "1"
    },
    {
      "name": "Himachal Pradesh",
      "code": "2"
    },
    {
      "name": "Punjab",
      "code": "3"
    },
    {
      "name": "Chandigarh",
      "code": "4"
    },
    {
      "name": "Uttarakhand",
      "code": "5"
    },
    {
      "name": "Haryana",
      "code": "6"
    },
    {
      "name": "Delhi",
      "code": "7"
    },
    {
      "name": "Rajasthan",
      "code": "8"
    },
    {
      "name": "Uttar Pradesh",
      "code": "9"
    },
    {
      "name": "Bihar",
      "code": "10"
    },
    {
      "name": "Sikkim",
      "code": "11"
    },
    {
      "name": "Arunachal Pradesh",
      "code": "12"
    },
    {
      "name": "Nagaland",
      "code": "13"
    },
    {
      "name": "Manipur",
      "code": "14"
    },
    {
      "name": "Mizoram",
      "code": "15"
    },
    {
      "name": "Tripura",
      "code": "16"
    },
    {
      "name": "Meghalaya",
      "code": "17"
    },
    {
      "name": "Assam",
      "code": "18"
    },
    {
      "name": "West Bengal",
      "code": "19"
    },
    {
      "name": "Jharkhand",
      "code": "20"
    },
    {
      "name": "Odisha",
      "code": "21"
    },
    {
      "name": "Chattisgarh",
      "code": "22"
    },
    {
      "name": "Madhya Pradesh",
      "code": "23"
    },
    {
      "name": "Gujarat",
      "code": "24"
    },
    {
      "name": "Dadra And Nagar Haveli And Daman And Diu",
      "code": "26"
    },
    {
      "name": "Maharashtra",
      "code": "27"
    },
    {
      "name": "Karnataka",
      "code": "29"
    },
    {
      "name": "Goa",
      "code": "30"
    },
    {
      "name": "Lakshadweep",
      "code": "31"
    },
    {
      "name": "Kerala",
      "code": "32"
    },
    {
      "name": "Tamil Nadu",
      "code": "33"
    },
    {
      "name": "Puducherry",
      "code": "34"
    },
    {
      "name": "Andaman And Nicobar Islands",
      "code": "35"
    },
    {
      "name": "Telangana",
      "code": "36"
    },
    {
      "name": "Andhra Pradesh",
      "code": "37"
    },
    {
      "name": "Ladakh",
      "code": "38"
    }
  ]
}


export const gastRates = () => {
  return [
    {
      "name": "None",
      "code": "0"
    },
    {
      "name": "Exempted",
      "code": "0"
    },
    {
      "name": "GST @ 0%",
      "code": "0"
    },
    {
      "name": "GST @ 0.1%",
      "code": "0.1"
    },
    {
      "name": "GST @ 0.25%",
      "code": "0.25"
    },
    {
      "name": "GST @ 3%",
      "code": "3"
    },
    {
      "name": "GST @ 5%",
      "code": "5"
    },
    {
      "name": "GST @ 12%",
      "code": "12"
    },
    {
      "name": "GST @ 18%",
      "code": "18"
    },
    {
      "name": "GST @ 14% + CESS @ 12%",
      "code": "14 + 12"
    },
    {
      "name": "GST @ 28%",
      "code": "28"
    },
    {
      "name": "GST @ 28% + CESS @ 12%",
      "code": "28 + 12"
    }
  ]
}

