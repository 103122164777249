import { combineReducers } from "redux";
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import questions, { questionSelected } from "./questionReducer";
import { ledger } from "./ledgerReducer";
import bankLedgerReducer from "./bankLedgersReduer";
import groupLedgerReducer from "./groupLedgerReducer";
import voucherReducer from "./voucherReducer";
import groupsReducer from "./groupsReducer";
import reportsReducer from "./reportsReducer";
import attachmentsReducer from "./attachmentsReducer";
import chipsReducer from "./chipsReducer";
import remarksReducer from "./remarksReducer";
import queryReducer from "./queryReducer";
import chatReducer from "./chatReducer";
import registration from "./registrationReducer";
import expenseReduser from "./expenseReducer";
import trnasactionTypeDateFilterReducer from "./trnasactionTypeDateFilterReducer";
import miscReducer from "./miscReducer";
import cashPaymentsReducer from "./cashPaymentsReducer";
import salesReducer from "./salesReducer";
import purchaseReducer from "./purchaseReducer";
import dashBoardReducer from "./dashBoardReducer";
import invenoryReducer from "./inventoryReducer";
import globalFilterReducer from "./globalFilterReducer"
import paymentReducer from "./paymentReducer"
import getGroupDataReducer from './getGroupDataReducer';
import postGroupDataReducer from './postGroupDataReducer';
import salesOrderReducer from './salesOrderReducer'
import userReduser from './userReducer'
import chartReducer from './chartReducer'
import pushNotificationReducer from './pushNotificationReducer'
import dataSearchReducer from "./dataSearchReducer"
import highlightsReducer from './highlightsReducer'
import budgetReducer from './budgetReducer';
import alertsReducer from './alertsReducer';
import gstReducer from './gstReducer';
import subscriptionReducer from './subscriptionReducer';
import saleInvoiceReducer from './saleInvoiceReducer';
import contactsReducer from './contactsReducer';

const initialState = {
  sidebarShow: "responsive",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userReduser"]
}

const appReducer = combineReducers({
  changeState,
  questions: questions,
  questionSelected,
  ledger,
  bankLedgerReducer,
  groupLedgerReducer,
  voucherReducer,
  groupsReducer,
  reportsReducer,
  attachmentsReducer,
  chipsReducer,
  remarksReducer,
  queryReducer,
  chatReducer,
  registration,
  expenseReduser,
  trnasactionTypeDateFilterReducer,
  miscReducer,
  cashPaymentsReducer,
  salesReducer,
  purchaseReducer,
  dashBoardReducer,
  invenoryReducer,
  globalFilterReducer,
  paymentReducer,
  getGroupDataReducer,
  postGroupDataReducer,
  salesOrderReducer,
  userReduser,
  chartReducer,
  pushNotificationReducer,
  dataSearchReducer,
  highlightsReducer,
  budgetReducer,
  alertsReducer,
  gstReducer,
  subscriptionReducer,
  saleInvoiceReducer,
  contactsReducer,
});
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
export default persistReducer(persistConfig, rootReducer);

